import React from "react";
import * as images from "../../constant/images";
import { Link } from "react-router-dom";

const Pakages = () => {
  return (
    <>
      <div className="about_main_div">
        <div className="container">
          <div className="row g-0 align-items-center justify-content-center">
            <div className="col-lg-6 col-md-5 col-sm-12">
              <div className="text-center mt-5 pt-5 pb-5 mb-5">
                <h1 className="text-white ace_span22 ms-2 me-2 m-0 p-0">
                  Pakages
                </h1>
                <h1 className="ace_h1 mt-2 p-0 m-0">
                  <h4 className="ace_h4">
                    <span className="ace_h45">
                      [CHEAPEST Pakages IN THE WORLD]
                    </span>
                  </h4>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row mainPage">
          <div className="col-lg-12 col-md-12 col-sm-12 pt-3 pt-md-0">
            <div className="row  pt-5 smScreen sm-py-20">
              <div className="col-lg-5 col-md-5 col-sm-12">
                <h1 className="ace_h1_21 p-0 m-0">
                  <span className="ace_span m-0 p-0">Pakages</span>
                </h1>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">Choose your package now!</h4>
                </h5>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
            <div className="ps-2">
              <h1 className="ace_h1_21 mt-5 p-0 m-0">
                <span className="ace_span m-0 p-0 text-center">
                  USD 1.00 per month per country only.
                </span>
              </h1>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-6">
            <div className="border border-2 rounded-3 p-3 mt-4 mb-5">
              <div className="text-center">
                <img
                  src={images.Pakege}
                  alt="team07"
                  className="rounded-3 text-center img-fluid"
                />
              </div>

              <h4 className="ace_h4 mt-3">Starter ($50.00 Only)</h4>
              <p className="pragraphSty mt-3">
                With this package you can super target your services and
                products in 50 countries.
              </p>
              <Link className="listiFirst" to="/payment-gateway">
                <button className="eye_btn rounded p-2 ps-3 pe-3">
                  BUY NOW
                </button>
              </Link>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-6">
            <div className="border border-2 rounded-3 p-3 mt-4 mb-5">
              <div className="text-center">
                <img
                  src={images.Pakege}
                  alt="team07"
                  className="rounded-3 text-center img-fluid"
                />
              </div>

              <h4 className="ace_h4 mt-3">Economy ($80.00 Only)</h4>
              <p className="pragraphSty mt-3">
                With this package you can super target your services and
                products in 100 countries.
              </p>
              <Link className="listiFirst" to="/payment-gateway">
                <button className="eye_btn rounded p-2 ps-3 pe-3">
                  BUY NOW
                </button>
              </Link>
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-6">
            <div className="border border-2 rounded-3 p-3 mt-4 mb-5">
              <div className="text-center">
                <img
                  src={images.Pakege}
                  alt="team07"
                  className="rounded-3 text-center img-fluid"
                />
              </div>

              <h4 className="ace_h4 mt-3">Super Economy ($200.00 Only)</h4>
              <p className="pragraphSty mt-3">
                With this package you can super target your services and
                products in 250 countries.
              </p>
              <Link className="listiFirst" to="/payment-gateway">
                <button className="eye_btn rounded p-2 ps-3 pe-3">
                  BUY NOW
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Pakages;
