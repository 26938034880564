import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Sidebar from './DashboardSidebar';
import DashboardNavbar from './DashboardNavbar';
import ContactList from './tabsContents/ContactList';
import ProgramsList from './tabsContents/ProgramsList';
import BlogList from './tabsContents/BlogList';

const DashboardLayout = () => {
  return (
    <div className="app-container">
      <Sidebar />
      <div className="main-content">
        <DashboardNavbar />
        <div className="content-area">
          <Routes>
            <Route path="tab1" element={<ContactList />} />
            <Route path="tab2" element={<ProgramsList />} />
            <Route path="tab3" element={<BlogList />} />
            <Route path="/" element={<ContactList />} /> {/* Default route */}
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;