import React, { useEffect, useState } from "react";
import { getRequest } from "../../services/api";

const ProgramsList = () => {
  const [contactData, setContactData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getRequest("/api/programform");
        setContactData(response.data);
      } catch (error) {
        console.error("Error fetching contact data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const filteredData = contactData.filter((contact) => {
    const searchLower = searchTerm.toLowerCase();
    return (
      (contact.fullName?.toLowerCase() || "").includes(searchLower) ||
      (contact.email?.toLowerCase() || "").includes(searchLower) ||
      (contact.profession?.toLowerCase() || "").includes(searchLower) ||
      (contact.nationality?.toLowerCase() || "").includes(searchLower) ||
      (contact.phoneNumber?.toString().toLowerCase() || "").includes(
        searchLower
      ) ||
      (contact.cityOfResidence?.toLowerCase() || "").includes(searchLower) ||
      (contact.portalName?.toLowerCase() || "").includes(searchLower) ||
      (contact.countryName?.toLowerCase() || "").includes(searchLower) ||
      (contact.comments?.toLowerCase() || "").includes(searchLower)
    );
  });

  const indexOfLastRow = currentPage * entriesPerPage;
  const indexOfFirstRow = indexOfLastRow - entriesPerPage;
  const currentEntries = filteredData.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(filteredData.length / entriesPerPage);

  const nowDate = () => {
    const date = new Date();
    return date.toLocaleDateString();
  };

  return (
    <div className="container">
      <h3 className="ace_h1_95 p-0 pb-3 m-0">Portal Users</h3>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearch}
              />
              <div className="table-responsive">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Profession</th>
                      <th>Nationality</th>
                      <th>Phone Number</th>
                      <th>City Of Residence</th>
                      <th>Portal Name</th>
                      <th>City Name</th>
                      <th>Comments</th>
                      <th>Published Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentEntries.map((contact, index) => (
                      <tr key={index}>
                        <td>{contact.fullName || "No name"}</td>
                        <td>{contact.email || "No email"}</td>
                        <td>{contact.profession || "No profession"}</td>
                        <td>{contact.nationality || "No nationality"}</td>
                        <td>{contact.phoneNumber || "No phone number"}</td>
                        <td>
                          {contact.cityOfResidence || "No city of residence"}
                        </td>
                        <td>{contact.portalName || "No city of residence"}</td>
                        <td>{contact.countryName || "No city of residence"}</td>
                        <td>{contact.comments || "No comments"}</td>
                        <td>{nowDate()}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  Showing {indexOfFirstRow + 1} to
                  {Math.min(indexOfLastRow, filteredData.length)} of
                  {filteredData.length} entries
                </div>
                <ul className="pagination">
                  {[...Array(totalPages)].map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${currentPage === index + 1 ? "active" : ""
                        }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgramsList;
