import AD from "../assets/images/flags/ad.svg";
import AE from "../assets/images/flags/ae.svg";
import AF from "../assets/images/flags/af.svg";
import AG from "../assets/images/flags/ag.svg";
import AL from "../assets/images/flags/al.svg";
import AM from "../assets/images/flags/am.svg";
import AO from "../assets/images/flags/ao.svg";
import AR from "../assets/images/flags/ar.svg";
import AT from "../assets/images/flags/at.svg";
import AU from "../assets/images/flags/au.svg";
import AZ from "../assets/images/flags/az.svg";
import BA from "../assets/images/flags/ba.svg";
import BB from "../assets/images/flags/bb.svg";
import BD from "../assets/images/flags/bd.svg";
import BE from "../assets/images/flags/be.svg";
import BF from "../assets/images/flags/bf.svg";
import BG from "../assets/images/flags/bg.svg";
import BH from "../assets/images/flags/bh.svg";
import BI from "../assets/images/flags/bi.svg";
import BJ from "../assets/images/flags/bj.svg";
import BN from "../assets/images/flags/bn.svg";
import BO from "../assets/images/flags/bo.svg";
import BR from "../assets/images/flags/br.svg";
import BS from "../assets/images/flags/bs.svg";
import BT from "../assets/images/flags/bt.svg";
import BW from "../assets/images/flags/bw.svg";
import BY from "../assets/images/flags/by.svg";
import BZ from "../assets/images/flags/bz.svg";
import CA from "../assets/images/flags/ca.svg";
import CD from "../assets/images/flags/cd.svg";
import CF from "../assets/images/flags/cf.svg";
import CG from "../assets/images/flags/cg.svg";
import CH from "../assets/images/flags/ch.svg";
import CI from "../assets/images/flags/ci.svg";
import CL from "../assets/images/flags/cl.svg";
import CM from "../assets/images/flags/cm.svg";
import CN from "../assets/images/flags/cn.svg";
import CO from "../assets/images/flags/co.svg";
import CR from "../assets/images/flags/cr.svg";
import CU from "../assets/images/flags/cu.svg";
import CV from "../assets/images/flags/cv.svg";
import CY from "../assets/images/flags/cy.svg";
import CZ from "../assets/images/flags/cz.svg";
import DE from "../assets/images/flags/de.svg";
import DJ from "../assets/images/flags/dj.svg";
import DK from "../assets/images/flags/dk.svg";
import DM from "../assets/images/flags/dm.svg";
import DO from "../assets/images/flags/do.svg";
import DZ from "../assets/images/flags/dz.svg";
import EC from "../assets/images/flags/ec.svg";
import EE from "../assets/images/flags/ee.svg";
import EG from "../assets/images/flags/eg.svg";
import EH from "../assets/images/flags/eh.svg";
import ER from "../assets/images/flags/er.svg";
import ES from "../assets/images/flags/es.svg";
import ET from "../assets/images/flags/et.svg";
import FI from "../assets/images/flags/fi.svg";
import FJ from "../assets/images/flags/fj.svg";
import FM from "../assets/images/flags/fm.svg";
import FR from "../assets/images/flags/fr.svg";
import GA from "../assets/images/flags/ga.svg";
import GB from "../assets/images/flags/gb.svg";
import GD from "../assets/images/flags/gd.svg";
import GE from "../assets/images/flags/ge.svg";
import GH from "../assets/images/flags/gh.svg";
import GM from "../assets/images/flags/gm.svg";
import GN from "../assets/images/flags/gn.svg";
import GQ from "../assets/images/flags/gq.svg";
import GR from "../assets/images/flags/gr.svg";
import GT from "../assets/images/flags/gt.svg";
import GW from "../assets/images/flags/gw.svg";
import GY from "../assets/images/flags/gy.svg";
import HN from "../assets/images/flags/hn.svg";
import HR from "../assets/images/flags/hr.svg";
import HT from "../assets/images/flags/ht.svg";
import HU from "../assets/images/flags/hu.svg";
import ID from "../assets/images/flags/id.svg";
import IE from "../assets/images/flags/ie.svg";
import IL from "../assets/images/flags/il.svg";
import IN from "../assets/images/flags/in.svg";
import IQ from "../assets/images/flags/iq.svg";
import IR from "../assets/images/flags/ir.svg";
import IS from "../assets/images/flags/is.svg";
import IT from "../assets/images/flags/it.svg";
import JM from "../assets/images/flags/jm.svg";
import JO from "../assets/images/flags/jo.svg";
import JP from "../assets/images/flags/jp.svg";
import KE from "../assets/images/flags/ke.svg";
import KG from "../assets/images/flags/kg.svg";
import KH from "../assets/images/flags/kh.svg";
import KI from "../assets/images/flags/ki.svg";
import KM from "../assets/images/flags/km.svg";
import KN from "../assets/images/flags/kn.svg";
import KP from "../assets/images/flags/kp.svg";
import KR from "../assets/images/flags/kr.svg";
import KW from "../assets/images/flags/kw.svg";
import KZ from "../assets/images/flags/kz.svg";
import LA from "../assets/images/flags/la.svg";
import LB from "../assets/images/flags/lb.svg";
import LC from "../assets/images/flags/lc.svg";
import LI from "../assets/images/flags/li.svg";
import LK from "../assets/images/flags/lk.svg";
import LR from "../assets/images/flags/lr.svg";
import LS from "../assets/images/flags/ls.svg";
import LT from "../assets/images/flags/lt.svg";
import LU from "../assets/images/flags/lu.svg";
import LV from "../assets/images/flags/lv.svg";
import LY from "../assets/images/flags/ly.svg";
import MA from "../assets/images/flags/ma.svg";
import MC from "../assets/images/flags/mc.svg";
import MD from "../assets/images/flags/md.svg";
import ME from "../assets/images/flags/me.svg";
import MG from "../assets/images/flags/mg.svg";
import MH from "../assets/images/flags/mh.svg";
import MK from "../assets/images/flags/mk.svg";
import ML from "../assets/images/flags/ml.svg";
import MM from "../assets/images/flags/mm.svg";
import MN from "../assets/images/flags/mn.svg";
import MR from "../assets/images/flags/mr.svg";
import MT from "../assets/images/flags/mt.svg";
import MU from "../assets/images/flags/mu.svg";
import MV from "../assets/images/flags/mv.svg";
import MW from "../assets/images/flags/mw.svg";
import MX from "../assets/images/flags/mx.svg";
import MY from "../assets/images/flags/my.svg";
import MZ from "../assets/images/flags/mz.svg";
import NA from "../assets/images/flags/na.svg";
import NE from "../assets/images/flags/ne.svg";
import NG from "../assets/images/flags/ng.svg";
import NI from "../assets/images/flags/ni.svg";
import NL from "../assets/images/flags/nl.svg";
import NO from "../assets/images/flags/no.svg";
import NP from "../assets/images/flags/np.svg";
import NR from "../assets/images/flags/nr.svg";
import NU from "../assets/images/flags/nu.svg";
import NZ from "../assets/images/flags/nz.svg";
import OM from "../assets/images/flags/om.svg";
import PA from "../assets/images/flags/pa.svg";
import PE from "../assets/images/flags/pe.svg";
import PG from "../assets/images/flags/pg.svg";
import PH from "../assets/images/flags/ph.svg";
import PK from "../assets/images/flags/pk.svg";
import PL from "../assets/images/flags/pl.svg";
import PT from "../assets/images/flags/pt.svg";
import PW from "../assets/images/flags/pw.svg";
import PY from "../assets/images/flags/py.svg";
import QA from "../assets/images/flags/qa.svg";
import RO from "../assets/images/flags/ro.svg";
import RS from "../assets/images/flags/rs.svg";
import RU from "../assets/images/flags/ru.svg";
import RW from "../assets/images/flags/rw.svg";
import SA from "../assets/images/flags/sa.svg";
import SB from "../assets/images/flags/sb.svg";
import SC from "../assets/images/flags/sc.svg";
import SD from "../assets/images/flags/sd.svg";
import SE from "../assets/images/flags/se.svg";
import SG from "../assets/images/flags/sg.svg";
import SI from "../assets/images/flags/si.svg";
import SK from "../assets/images/flags/sk.svg";
import SL from "../assets/images/flags/sl.svg";
import SM from "../assets/images/flags/sm.svg";
import SN from "../assets/images/flags/sn.svg";
import SO from "../assets/images/flags/so.svg";
import SR from "../assets/images/flags/sr.svg";
import SS from "../assets/images/flags/ss.svg";
import ST from "../assets/images/flags/st.svg";
import SV from "../assets/images/flags/sv.svg";
import SY from "../assets/images/flags/sy.svg";
import SZ from "../assets/images/flags/sz.svg";
import TD from "../assets/images/flags/td.svg";
import TG from "../assets/images/flags/tg.svg";
import TH from "../assets/images/flags/th.svg";
import TJ from "../assets/images/flags/tj.svg";
import TL from "../assets/images/flags/tl.svg";
import TM from "../assets/images/flags/tm.svg";
import TN from "../assets/images/flags/tn.svg";
import TO from "../assets/images/flags/to.svg";
import TR from "../assets/images/flags/tr.svg";
import TT from "../assets/images/flags/tt.svg";
import TV from "../assets/images/flags/tv.svg";
import TZ from "../assets/images/flags/tz.svg";
import UA from "../assets/images/flags/ua.svg";
import UG from "../assets/images/flags/ug.svg";
import US from "../assets/images/flags/us.svg";
import UY from "../assets/images/flags/uy.svg";
import UZ from "../assets/images/flags/uz.svg";
import VA from "../assets/images/flags/va.svg";
import VC from "../assets/images/flags/vc.svg";
import VE from "../assets/images/flags/ve.svg";
import VN from "../assets/images/flags/vn.svg";
import VU from "../assets/images/flags/vu.svg";
import WS from "../assets/images/flags/ws.svg";
import YE from "../assets/images/flags/ye.svg";
import ZA from "../assets/images/flags/za.svg";
import ZM from "../assets/images/flags/zm.svg";
import ZW from "../assets/images/flags/zw.svg";

// Add more imports as needed

export const flags = {
  AD,
  AE,
  AF,
  AG,
  AL,
  AM,
  AO,
  AR,
  AT,
  AU,
  AZ,
  BA,
  BB,
  BD,
  BE,
  BF,
  BG,
  BH,
  BI,
  BJ,
  BN,
  BO,
  BR,
  BS,
  BT,
  BW,
  BY,
  BZ,
  CA,
  CD,
  CF,
  CG,
  CH,
  CI,
  CL,
  CM,
  CN,
  CO,
  CR,
  CU,
  CV,
  CY,
  CZ,
  DE,
  DJ,
  DK,
  DM,
  DO,
  DZ,
  EC,
  EE,
  EG,
  EH,
  ER,
  ES,
  ET,
  FI,
  FJ,
  FM,
  FR,
  GA,
  GB,
  GD,
  GE,
  GH,
  GM,
  GN,
  GQ,
  GR,
  GT,
  GW,
  GY,
  HN,
  HR,
  HT,
  HU,
  ID,
  IE,
  IL,
  IN,
  IQ,
  IR,
  IS,
  IT,
  JM,
  JO,
  JP,
  KE,
  KG,
  KH,
  KI,
  KM,
  KN,
  KP,
  KR,
  KW,
  KZ,
  LA,
  LB,
  LC,
  LI,
  LK,
  LR,
  LS,
  LT,
  LU,
  LV,
  LY,
  MA,
  MC,
  MD,
  ME,
  MG,
  MH,
  MK,
  ML,
  MM,
  MN,
  MR,
  MT,
  MU,
  MV,
  MW,
  MX,
  MY,
  MZ,
  NA,
  NE,
  NG,
  NI,
  NL,
  NO,
  NP,
  NR,
  NU,
  NZ,
  OM,
  PA,
  PE,
  PG,
  PH,
  PK,
  PL,
  PT,
  PW,
  PY,
  QA,
  RO,
  RS,
  RU,
  RW,
  SA,
  SB,
  SC,
  SD,
  SE,
  SG,
  SI,
  SK,
  SL,
  SM,
  SN,
  SO,
  SR,
  SS,
  ST,
  SV,
  SY,
  SZ,
  TD,
  TG,
  TH,
  TJ,
  TL,
  TM,
  TN,
  TO,
  TR,
  TT,
  TV,
  TZ,
  UA,
  UG,
  US,
  UY,
  UZ,
  VA,
  VC,
  VE,
  VN,
  VU,
  WS,
  YE,
  ZA,
  ZM,
  ZW,
  // Add more countries and their respective flag images here
};
