import React, { useEffect, useState } from "react";
import { getRequest } from "../../services/api";
import Profile from "../../assets/images/Ammad.jpeg";

const BlogList = () => {
  const [contactData, setContactData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 5;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getRequest("/api/blogform");
        setContactData(response.data);
      } catch (error) {
        console.error("Error fetching contact data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };

  const filteredData = contactData.filter((contact) => {
    const term = searchTerm.toLowerCase();
    return (
      (contact.fullName && contact.fullName.toLowerCase().includes(term)) ||
      (contact.email && contact.email.toLowerCase().includes(term)) ||
      (contact.address && contact.address.toLowerCase().includes(term)) ||
      (contact.message && contact.message.toLowerCase().includes(term))
    );
  });

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredData.slice(indexOfFirstRow, indexOfLastRow);
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="container">
      <h3 className="ace_h1_95 p-0 pb-3 m-0">Contact Users</h3>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <input
                type="text"
                className="form-control mb-3"
                placeholder="Search..."
                value={searchTerm}
                onChange={handleSearch}
              />
              <div className="table-responsive">
                <table className="table table-striped table-bordered table-hover">
                  <thead>
                    <tr>
                      <th>Profile Image</th>
                      <th>Author Name</th>
                      <th>Blog Title</th>
                      <th>Published Country List</th>
                      <th>Published Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentRows.map((contact, index) => (
                      <tr key={index} onClick={() => alert(`Row ${index + 1} clicked`)}>
                        <td className="text-center">
                          <img
                            src={Profile}
                            alt="Profile"
                            className="rounded-circle"
                            style={{ width: "50px", height: "50px" }}
                          />
                        </td>
                        <td>{contact.fullName || "No name"}</td>
                        <td>{contact.email || "No email"}</td>
                        <td>{contact.address || "No address"}</td>
                        <td>{contact.message || "No message"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  Showing {indexOfFirstRow + 1} to {Math.min(indexOfLastRow, filteredData.length)} of {filteredData.length} entries
                </div>
                <ul className="pagination">
                  {[...Array(totalPages)].map((_, index) => (
                    <li key={index} className={`page-item ${currentPage === index + 1 ? "active" : ""}`}>
                      <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogList;
