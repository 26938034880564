import React, { useState, useContext, useEffect } from "react";
import { BlogContext } from "../../context/BlogContext";
import { useNavigate } from "react-router-dom";
import { postRequest } from "../../services/api";

const BlogAddForm = () => {
  const [image, setImage] = useState(null);
  const [secondImage, setSecondImage] = useState(null);
  const [title, setTitle] = useState("");
  const [introductionLine, setIntroductionLine] = useState("");
  const [paragraph1, setParagraph1] = useState("");
  const [paragraph2, setParagraph2] = useState("");
  const [paragraph3, setParagraph3] = useState("");
  const [heading2, setHeading2] = useState("");
  const { addBlog } = useContext(BlogContext);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleImage2Change = (e) => {
    setSecondImage(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('introductionLine', introductionLine);
    formData.append('paragraph1', paragraph1);
    formData.append('paragraph2', paragraph2);
    formData.append('paragraph3', paragraph3);
    formData.append('heading2', heading2);
    if (image) formData.append('image', image);
    if (secondImage) formData.append('secondImage', secondImage);

    try {
      const response = await postRequest("/api/blogform", formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (response.data.status) {
        // Add the new blog to the context
        addBlog(response.data.blog);
        navigate("/blogs-home-page");
      }
    } catch (error) {
      console.error("Error submitting blog form:", error);
    }
  };

  return (
    <div className="container">
      <h1 className="ace_span36 text-center ms-2 me-2 m-0 pt-4 p-0">
        Add New Blog
      </h1>
      <div className="row justify-content-center mt-4">
        <div className="col-md-8 col-12">
          <form onSubmit={handleSubmit}>
            <div className="row mb-3">
              <div className="col-md-6 mb-3 mb-md-0">
                <label htmlFor="title" className="form-label">
                  Main Title <span className="input_span">(Required)</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  placeholder="Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="introductionLine" className="form-label">
                  Introduction Line
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="introductionLine"
                  placeholder="Introduction Line"
                  value={introductionLine}
                  onChange={(e) => setIntroductionLine(e.target.value)}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6 mb-3 mb-md-0">
                <label htmlFor="paragraph1" className="form-label">
                  First Paragraph
                </label>
                <textarea
                  className="form-control"
                  id="paragraph1"
                  placeholder="Paragraph"
                  value={paragraph1}
                  onChange={(e) => setParagraph1(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="paragraph2" className="form-label">
                  Second Paragraph
                </label>
                <textarea
                  className="form-control"
                  id="paragraph2"
                  placeholder="Paragraph"
                  value={paragraph2}
                  onChange={(e) => setParagraph2(e.target.value)}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-6 mb-3 mb-md-0">
                <label htmlFor="paragraph3" className="form-label">
                  Third Paragraph
                </label>
                <textarea
                  className="form-control"
                  id="paragraph3"
                  placeholder="Paragraph"
                  value={paragraph3}
                  onChange={(e) => setParagraph3(e.target.value)}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="heading2" className="form-label">
                  First Heading
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="heading2"
                  placeholder="Heading"
                  value={heading2}
                  onChange={(e) => setHeading2(e.target.value)}
                />
              </div>
            </div>
            <div className="mb-3">
              <label htmlFor="image" className="form-label">
                Upload First Image
              </label>
              <input
                type="file"
                className="form-control"
                accept="image/*"
                onChange={handleImageChange}
              />
              {image && (
                <div className="mt-3">
                  <img
                    src={URL.createObjectURL(image)}
                    alt="Preview"
                    className="img-thumbnail"
                    style={{ maxHeight: "200px" }}
                  />
                </div>
              )}
            </div>
            <div className="mb-3">
              <label htmlFor="secondImage" className="form-label">
                Upload Second Image
              </label>
              <input
                type="file"
                className="form-control"
                accept="image/*"
                onChange={handleImage2Change}
              />
              {secondImage && (
                <div className="mt-3">
                  <img
                    src={URL.createObjectURL(secondImage)}
                    alt="Preview"
                    className="img-thumbnail"
                    style={{ maxHeight: "200px" }}
                  />
                </div>
              )}
            </div>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default BlogAddForm;
