import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { postRequest } from "../../services/api";

const PartnerForm = () => {
  const location = useLocation();
  const { countryName } = location.state || {};

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    fullName: "",
    nationality: "",
    profession: "",
    email: "",
    phoneNumber: "",
    cityOfResidence: "",
    portalName: "Partnership Portal",
    countryName: countryName || "",
    comments: "",
    emailError: false,
    emailMessage: "",
  });
  const [phoneError, setPhoneError] = useState(false);

  const validateEmail = (email) => {
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });

    if (id === "email") {
      const isValidEmail = validateEmail(value);
      setFormData((prevData) => ({
        ...prevData,
        emailError: !isValidEmail,
        emailMessage: isValidEmail ? "" : "Invalid email",
      }));
    }
  };

  const validatePhoneNumber = (input_str) => {
    const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im;
    return re.test(input_str);
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phoneNumber: value });
    setPhoneError(!validatePhoneNumber(value));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (phoneError || !validatePhoneNumber(formData.phoneNumber)) {
      setPhoneError(true);
      return;
    }
    if (!validateEmail(formData.email)) {
      setFormData((prevData) => ({
        ...prevData,
        emailError: true,
        emailMessage: "Invalid email",
      }));
      return;
    }

    try {
      const response = await postRequest("/api/programform", formData);
      const result = await response.data;
      console.log(result);
      if (result.status === true) {
        // Check for the presence of key
        Swal.fire({
          icon: "success",
          title: "Successful",
          text: "Our team will shortly get in touch with you for further discussion.",
        });

        setFormData({
          fullName: "",
          nationality: "",
          profession: "",
          email: "",
          phoneNumber: "",
          cityOfResidence: "",
          portalName: "Partnership Portal",
          countryName: countryName || "",
          comments: "",
          emailError: false,
          emailMessage: "",
        });
      } else {
        console.log(result.message || "Unknown error");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to submit form. Please try again.",
      });
    }
  };

  return (
    <div className="container-fluid p-0 m-0">
      <h1 className="ace_h1 mt-4 p-0 m-0 text-center">
        <span className="ace_span ms-2 me-2 m-0 p-0">
          Partnership PORTAL Application
        </span>
      </h1>
      <div className="container">
        <div className="row g-0 align-items-center justify-content-center">
          <div className="col-lg-5 col-md-5 col-sm-10">
            <form onSubmit={handleSubmit} className="mt-3">
              <div className="mb-3 w-100 mt-4">
                <label htmlFor="fullName" className="form-label">
                  Full Name as shown on government ID/ Passport
                  <span className="input_span">&nbsp;(Required)</span>
                </label>
                <input
                  type="text"
                  className="form-control w-100"
                  id="fullName"
                  placeholder="Name"
                  value={formData.fullName}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="mb-3 w-100 mt-4">
                <label htmlFor="nationality" className="form-label">
                  Nationality
                  <span className="input_span">&nbsp;(Required)</span>
                </label>
                <input
                  type="text"
                  className="form-control w-100"
                  id="nationality"
                  placeholder="Nationality"
                  value={formData.nationality}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="mb-3 w-100 mt-4">
                <label htmlFor="profession" className="form-label">
                  Profession
                  <span className="input_span">&nbsp;(Required)</span>
                </label>
                <input
                  type="text"
                  className="form-control w-100"
                  id="profession"
                  placeholder="Profession"
                  value={formData.profession}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="mb-3 w-100 mt-4">
                <label htmlFor="cityOfResidence" className="form-label">
                  City of Residence
                  <span className="input_span">&nbsp;(Required)</span>
                </label>
                <input
                  type="text"
                  className="form-control w-100"
                  id="cityOfResidence"
                  placeholder="City of Residence"
                  value={formData.cityOfResidence}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="mb-3 w-100 mt-5">
                <label htmlFor="email" className="form-label">
                  Email<span className="input_span">&nbsp;(Required)</span>
                </label>
                <input
                  type="email"
                  className="form-control w-100"
                  id="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
                {formData.emailError && (
                  <span className="text-danger">{formData.emailMessage}</span>
                )}
              </div>

              <div className="mb-3 w-100 mt-5">
                <label htmlFor="phoneNumber" className="form-label">
                  Phone Number
                  <span className="input_span">&nbsp;(Required)</span>
                </label>
                <PhoneInput
                  country={"us"}
                  value={formData.phoneNumber}
                  onChange={handlePhoneChange}
                  inputProps={{
                    name: "phoneNumber",
                    required: true,
                    autoFocus: true,
                  }}
                  inputClass="form-control"
                  containerClass="phone-input-container"
                  inputStyle={{ width: "100%" }}
                />
                {phoneError && (
                  <span className="text-danger">
                    Invalid phone number. Please enter a valid phone number.
                  </span>
                )}
              </div>

              <div className="mb-3 w-100 mt-5">
                <label htmlFor="portalName" className="form-label">
                  Portal Name
                </label>
                <input
                  type="text"
                  className="form-control w-100"
                  id="portalName"
                  placeholder="Portal Name"
                  value={formData.portalName}
                  readOnly
                />
              </div>

              <div className="mb-3 w-100 mt-5">
                <label htmlFor="countryName" className="form-label">
                  Country Name
                </label>
                <input
                  type="text"
                  className="form-control w-100"
                  id="countryName"
                  placeholder="Country Name"
                  value={formData.countryName}
                  readOnly
                />
              </div>

              <div className="mb-3 w-100 mt-5">
                <label htmlFor="comments" className="form-label">
                  Comments
                  <span className="input_span">&nbsp;(Optional)</span>
                </label>
                <textarea
                  className="form-control"
                  id="comments"
                  rows="3"
                  placeholder="Your comments"
                  value={formData.comments}
                  onChange={handleChange}
                ></textarea>
              </div>

              <button
                type="submit"
                className="btn btn-danger w-100 mt-5"
                id="submit"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerForm;
