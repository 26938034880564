import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { BlogContext } from "../../context/BlogContext";
import * as images from "../../constant/images";

const BlogLandingPage = () => {
  const { title } = useParams();
  const { blogs } = useContext(BlogContext);
  const blog = blogs.find((b) => b.title === title);

  if (!blog) {
    return <div>Blog not found</div>;
  }
  return (
    <>
      <div className="blog_back_img">
        <h1 className="text-white ace_span24 ms-2 me-2 m-0 mt-5 pt-4 p-0">
          {blog.title}
        </h1>
      </div>
      <div className="container">
        <h1 className="ace_span12 m-0 p-0 mt-5">{blog.introductionLine}</h1>
        <div className="row mt-4">
          <div className="col-md-6 col-12">
            <p className="pragraphSty">{blog.paragraph1} </p>
          </div>
          <div className="col-md-6 col-12">
            <div className="text-center">
              <img
                src={blog.img}
                alt="Weight Loss Journey"
                className="rounded-3 text-center img-fluid"
              />
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-md-4 col-12 d-flex align-items-center">
            <div>
              <img
                src={images.blog4}
                alt="Healthy Eating"
                className="rounded-3 text-center img-fluid"
              />
            </div>
          </div>
          <div className="col-md-8 col-12 d-flex align-items-center">
            <div className="border border-2 rounded-3 p-3 w-100">
              <h5 className="globalizedSty my-3 text-capitalize">
                <h4 className="ace_h4">Must Read</h4>
              </h5>
              <p className="pragraphSty mt-3">{blog.paragraph2}</p>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-md-12 col-12">
            <h1 className="ace_span12 m-0 p-0 mt-5">{blog.heading2}</h1>
            <div className="d-flex justify-content-start mt-3">
              <p className="pragraphSty w-75">{blog.paragraph3}</p>
            </div>
            <div className="d-flex justify-content-end">
              <p className="pragraphSty w-75">{blog.paragraph1}</p>
            </div>

            <div className="text-center mt-4">
              <img
                src={blog.img}
                alt="Exercise Routine"
                className="rounded-3 text-center img-fluid"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogLandingPage;
