import React, { useState } from "react";

const BlogsTopicsPage = () => {
  const [selectedLetter, setSelectedLetter] = useState(null);
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

  const topicsData = {
    A: ["Adult Education", "Anatomy"],
    B: ["Biology Education", "Biomedical Research"],
    C: ["Child Education", "Clinical Trials"],
    D: ["Distance Learning", "Dentistry"],
    E: ["Early Childhood Education", "Epidemiology"],
    F: ["Forensic Medicine", "First Aid Education"],
    G: ["Graduate Education", "Genetics"],
    H: ["Health Education", "Healthcare Journalism"],
    I: ["Inclusive Education", "Immunology"],
    J: ["Journalism", "Journalistic Ethics"],
    K: ["Kinesiology", "Knowledge Dissemination"],
    L: ["Literacy Programs", "Laboratory Medicine"],
    M: ["Medical Education", "Mental Health"],
    N: ["Nursing Education", "Neuroscience"],
    O: ["Online Education", "Oncology"],
    P: ["Public Health Education", "Pediatrics"],
    Q: ["Qualitative Research", "Quality Healthcare"],
    R: ["Research Methodology", "Radiology"],
    S: ["Special Education", "Surgery"],
    T: ["Teaching Methods", "Therapeutics"],
    U: ["Undergraduate Education", "Urology"],
    V: ["Vocational Education", "Virology"],
    W: ["Women's Health", "Wellness Education"],
    X: ["X-ray Technology", "Xenotransplantation"],
    Y: ["Youth Education", "Yoga Therapy"],
    Z: ["Zoology Education", "Zoonotic Diseases"],
  };

  const allTopics = Object.values(topicsData).flat();

  const handleLetterClick = (letter) => {
    setSelectedLetter(letter);
  };

  return (
    <div>
      <div className="container-fluid">
        {/* Alphabet navigation */}
        {alphabet
          .reduce((rows, letter, idx) => {
            if (idx % 11 === 0) rows.push([]);
            rows[rows.length - 1].push(letter);
            return rows;
          }, [])
          .map((row, rowIndex) => (
            <div
              className="row g-0 mt-2 d-flex justify-content-center"
              key={rowIndex}
            >
              <div className="col-lg-8 col-md-8 col-sm-12">
                <div className="d-flex justify-content-around">
                  {row.map((letter) => (
                    <a
                      key={letter}
                      href={`#${letter}`}
                      className="text-decoration-none"
                      onClick={() => handleLetterClick(letter)}
                    >
                      <h6
                        className={`ace_h1_24 mt-1 m-0 border border-2 rounded ${
                          selectedLetter === letter ? "highlight" : ""
                        }`}
                      >
                        {letter}
                      </h6>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          ))}

        {/* "All Topics" Button */}
        <div className="row g-0 mt-2 d-flex justify-content-center">
          <div className="col-lg-8 col-md-8 col-sm-12">
            <div className="d-flex justify-content-center">
              <a
                href="#all"
                className="text-decoration-none"
                onClick={() => setSelectedLetter(null)}
              >
                <h6
                  className={` mt-1 m-0 p-2 ps-3 pe-3 border border-2 rounded ${
                    selectedLetter === null ? "highlight" : ""
                  }`}
                >
                  All Topics
                </h6>
              </a>
            </div>
          </div>
        </div>

        <div className="container mb-5 mt-4">
          <div className="row g-0">
            <div className="col-12 box_topics rounded-2 pt-4 pb-4">
              <div>
                <h4 className="ace_h4_3 mt-2 ace_p_2_5 ace_span23 d-flex gap-2 flex-wrap">
                  {(selectedLetter
                    ? topicsData[selectedLetter]
                    : allTopics
                  ).map((topic, idx) => (
                    <span
                      key={idx}
                      className="ps-3 pe-3"
                      style={{ fontSize: "15px", color: "gray" }}
                    >
                      {topic}
                    </span>
                  ))}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogsTopicsPage;
