import React, { useContext, useState } from "react";
import { BlogContext } from "../../context/BlogContext";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import BlogsTopicsPage from "./BlogsTopicsPage";

const BlogsHomePage = () => {
  const { blogs } = useContext(BlogContext);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = blogs.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(blogs.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };

  return (
    <>
      <div className="pakage_investor_back">
        <h1 className="text-white ace_span22 ms-2 me-2 m-0 p-0">All Blogs</h1>
      </div>
      <div className="container">
        <div className="container">
          <div className="row mainPage">
            <div className="col-lg-12 col-md-12 col-sm-12 pt-3 pt-md-0">
              <div className="row pt-5 smScreen sm-py-20">
                <div className="col-lg-5 col-md-5 col-sm-12">
                  <h1 className="ace_h1_21 p-0 m-0">
                    {/* <span className="ace_span m-0 p-0">Welcome to the {countryName} Blogging Portal</span> */}
                  </h1>
                  <h5 className="globalizedSty my-3 text-capitalize">
                    <h4 className="ace_h4">Click on any blog to read</h4>
                  </h5>
                </div>
              </div>
              <BlogsTopicsPage />
            </div>
          </div>
        </div>
        <div className="row mainPage">
          <div className="col-lg-12 col-md-12 col-sm-12 pt-3 pt-md-0">
            <div className="row pt-5 smScreen sm-py-20">
              <div className="col-12">
                <div className="d-flex justify-content-between">
                  <div>
                    <h1 className="ace_h1_21 p-0 m-0">
                      <span className="ace_span m-0 p-0">Blogs</span>
                    </h1>
                  </div>
                  <div>
                    <Link className="text-decoration-none" to="/blog-payment">
                      <button
                        className="eye_btn rounded p-2 ps-4 pe-4"
                        type="submit"
                      >
                        Add Blog
                      </button>
                    </Link>
                  </div>
                </div>
                <h5 className="globalizedSty my-3 text-capitalize">
                  <h4 className="ace_h4">
                    After sending the payment, you can view your blog here
                  </h4>
                </h5>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {currentItems.map((blog) => (
            <div className="col-lg-4 col-md-4 col-sm-6" key={blog.id}>
              <div className="border border-2 rounded-3 p-3 mt-4 mb-5">
                <div className="text-center">
                  <img
                    src={blog.img}
                    alt={`Blog ${blog.id}`}
                    className="rounded-3 text-center blogsetimg"
                  />
                </div>
                <h4 className="ace_h4 mt-3">{blog.title}</h4>
                <Link
                  className="text-decoration-none"
                  to={`/blog/${encodeURIComponent(blog.title)}`}
                >
                  <button
                    className="eye_btn rounded p-2 ps-3 pe-3"
                    onClick={scrollToTop}
                  >
                    READ MORE
                  </button>
                </Link>
              </div>
            </div>
          ))}
        </div>

        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <nav>
              <ul className="pagination">
                {[...Array(totalPages)].map((_, i) => (
                  <li
                    key={i}
                    className={`page-item ${currentPage === i + 1 ? "active" : ""
                      }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => paginate(i + 1)}
                    >
                      {i + 1}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogsHomePage;
