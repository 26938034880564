import React from "react";
import { Link } from "react-router-dom";

const PaymentGateway = () => {
  return (
    <div>
      <section className="p-4">
        <div className="row d-flex justify-content-center">
          <div className="col-md-10 col-lg-8 col-xl-5">
            <div className="card rounded-3">
              <div className="card-body p-4">
                <div className="text-center mb-4">
                  <h4 className="ace_h4">Payment Method</h4>
                </div>
                <form action="">
                  <p className="fw-bold mb-4 pb-2">Saved cards:</p>

                  <div className="d-flex flex-row align-items-baseline mb-4 pb-1">
                    <div>
                      <img
                        className="img-fluid"
                        src="https://img.icons8.com/color/48/000000/mastercard-logo.png"
                        alt="img"
                      />
                    </div>
                    <div className="flex-fill mx-3">
                      <div data-mdb-input-init className="form-outline">
                        <input
                          type="text"
                          id="formControlLgXc"
                          className="form-control form-control-lg"
                          value="**** **** **** 3193"
                        />
                        <label className="form-label" for="formControlLgXc">
                          Card Number
                        </label>
                      </div>
                    </div>
                    <div>
                      <a href="#!">Remove card</a>
                    </div>
                  </div>

                  <div className="d-flex flex-row align-items-center mb-4 pb-1">
                    <div>
                      <img
                        className="img-fluid"
                        src="https://img.icons8.com/color/48/000000/visa.png"
                        alt="img"
                      />
                    </div>
                    <div className="flex-fill mx-3">
                      <div data-mdb-input-init className="form-outline">
                        <input
                          type="text"
                          id="formControlLgXs"
                          className="form-control form-control-lg"
                          value="**** **** **** 4296"
                        />
                        <label className="form-label" for="formControlLgXs">
                          Card Number
                        </label>
                      </div>
                    </div>
                    <div>
                      <a href="#!">Remove card</a>
                    </div>
                  </div>

                  <p className="fw-bold mb-4">Add new card:</p>

                  <div data-mdb-input-init className="form-outline mb-4">
                    <input
                      type="text"
                      id="formControlLgXsd"
                      className="form-control form-control-lg"
                      value="Anna Doe"
                    />
                    <label className="form-label" for="formControlLgXsd">
                      Cardholder's Name
                    </label>
                  </div>

                  <div className="row mb-4">
                    <div className="col-7">
                      <div data-mdb-input-init className="form-outline">
                        <input
                          type="text"
                          id="formControlLgXM"
                          className="form-control form-control-lg"
                          value="1234 5678 1234 5678"
                        />
                        <label className="form-label" for="formControlLgXM">
                          Card Number
                        </label>
                      </div>
                    </div>
                    <div className="col-5">
                      <div data-mdb-input-init className="form-outline">
                        <input
                          type="password"
                          id="formControlLgExpk"
                          className="form-control form-control-lg"
                          placeholder="MM/YYYY"
                        />
                        <label className="form-label" for="formControlLgExpk">
                          Expire
                        </label>
                      </div>
                    </div>
                    <div className="col-12 mt-4">
                      <div data-mdb-input-init className="form-outline">
                        <input
                          type="password"
                          id="formControlLgcvv"
                          className="form-control form-control-lg"
                          placeholder="Cvv"
                        />
                        <label className="form-label" for="formControlLgcvv">
                          Cvv
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <Link to="/" className="text-decoration-none">
                      <button
                        data-mdb-button-init
                        data-mdb-ripple-init
                        type="submit"
                        className="eye_btn w-100"
                      >
                        Add card
                      </button>
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PaymentGateway;
