import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { BlogProvider } from "./context/BlogContext";
import App from "./App";

const root = createRoot(document.getElementById("root"));

root.render(
  <BlogProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </BlogProvider>
);
