import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import countryData from "../../constant/countryData";

const MainCompanyPage = () => {
  const [selectedLetter, setSelectedLetter] = useState(null);
  const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
  const navigate = useNavigate();

  const handleLetterClick = (letter) => {
    setSelectedLetter(letter);
    const element = document.getElementById(letter);
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };

  const handlePortalClick = (countryName, countryCode, portalName) => {

    navigate(`/our-companies/${portalName}/${countryName}`, {
      state: { countryName, countryCode, portalName },
    });

    // Call scrollToTop after a short delay to ensure navigation is completed
    setTimeout(scrollToTop, 0);
  };

  return (
    <div>
      <div className="company_back_img">
        <h1 className="text-white ace_span24 ms-2 me-2 m-0 p-0">
          Our Companies
        </h1>
      </div>
      <div className="container">
        <div className="row mt-5">
          <div className="col-12">
            <h1 className="ace_h1_21 p-0 m-0">
              <span className="ace_span m-0 p-0">
                Our Countries
              </span>
            </h1>
            <h5 className="globalizedSty my-3 text-capitalize">
              <h4 className="ace_h4">Select Your Country</h4>
            </h5>
          </div>
        </div>
      </div>
      <div>
        {alphabet.reduce((rows, letter, idx) => {
          if (idx % 11 === 0) rows.push([]);
          rows[rows.length - 1].push(letter);
          return rows;
        }, []).map((row, rowIndex) => (
          <div className="row g-0 mt-2 d-flex justify-content-center" key={rowIndex}>
            <div className="col-lg-8 col-md-8 col-sm-12">
              <div className="d-flex justify-content-around">
                {row.map((letter) => (
                  <a
                    key={letter}
                    href={`#${letter}`}
                    className="text-decoration-none"
                    onClick={(e) => {
                      e.preventDefault();
                      handleLetterClick(letter);
                    }}
                  >
                    <h4
                      className={`ace_h1_24 mt-1 m-0 border border-2 rounded ${selectedLetter === letter ? "highlight" : ""
                        }`}
                    >
                      {letter}
                    </h4>
                  </a>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-5">
        {alphabet.map((letter) => (
          <div id={letter} key={letter} className="mt-5">
            <div className="d-flex justify-content-center">
              <h3 className="text-center mt-1 m-0 border-bottom border-3 bordr-primary fw-bold">{letter}</h3>
            </div>
            <div className="d-flex justify-content-center">
              <ul className="list-unstyled text-center">
                {countryData
                  .filter((country) => country.name.startsWith(letter))
                  .map((country) => (
                    <li key={country.code} className="dropdown m-2">
                      <button className="drop_country33 p-2 ps-3 pe-3 dropdown_toggle" type="button" id={`dropdownMenuButton-${country.code}`} data-bs-toggle="dropdown" aria-expanded="false">
                        {country.name}
                      </button>
                      <ul className="dropdown-menu cursor-pointer dropdown_menu33" aria-labelledby={`dropdownMenuButton-${country.code}`}>
                        <li><a className="dropdown-item" href style={{ cursor: "pointer" }} onClick={() => handlePortalClick(country.name, country.code, "Partner Portal")}>Partner Portal</a></li>
                        <li><a className="dropdown-item" href style={{ cursor: "pointer" }} onClick={() => handlePortalClick(country.name, country.code, "Investor Portal")}>Investor Portal</a></li>
                        <li><a className="dropdown-item" href style={{ cursor: "pointer" }} onClick={() => handlePortalClick(country.name, country.code, "Franchising Portal")}>Franchising Portal</a></li>
                      </ul>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MainCompanyPage;
